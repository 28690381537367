<template>
  <section class="breadcrumbs">
    <div class="container">
      <div class="flex flex-wrap">
        <div class="w-full px-4">
          <ul>
            <li v-for="item in data">
              <nuxt-link
                :to="
                  localePath(item.url) + (item.query ? '?q=' + item.query : '')
                "
                v-html="item.name"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarBreadcrumbs',
  props: {
    data: {
      default: () => [
        {
          name: 'Home',
          url: 'index'
        }
      ],
      required: false,
      type: Array
    }
  }
}
</script>
