<template>
  <div>
    <section v-if="!inline" :class="`bar__categories bg-${bg}`">
      <div class="container px-4 px-md-0">
        <div class="grid grid-cols-2 tablet:grid-cols-5 gap-5 laptop:gap-8">
          <nuxt-link
            v-for="(productGroup, groupIndex) in productGroups"
            :key="'product-category-' + groupIndex"
            class="category"
            :target="productGroup.ext_url && productGroup.ext_url.length > 5 ? '_blank' : '_self'"
            :to="productGroup.ext_url && productGroup.ext_url.length > 5 ? productGroup.ext_url :
              localePath({
                name: 'products-group',
                params: {
                  group: $slugify(
                    productGroup.name
                      ? productGroup.name
                      : productGroup.fallback_name
                  )
                }
              })
            ">
            <img
              :alt="
                productGroup.name
                  ? productGroup.name
                  : productGroup.fallback_name
              "
              :src="
                productGroup.images.length > 0
                  ? productGroup.images[0]
                  : '/images/placeholder-selector.jpg'
              "
              class="category_img mx-auto"
              loading="lazy"
              height="81"
              width="121"/>
            <div class="category_name">
              <strong >
                {{ productGroup.name ? productGroup.name : productGroup.fallback_name }}
              </strong>
            </div>
          </nuxt-link>
        </div>
      </div>
    </section>
    <section v-else>
      <div class="grid grid-cols-1 tablet:grid-cols-3 gap-5 laptop:gap-8 px-4 pb-8">
        <nuxt-link
          v-for="(productGroup, groupIndex) in productGroups"
          :key="'product-category-' + groupIndex"
          class="category"
          :target="productGroup.ext_url && productGroup.ext_url.length > 5 ? '_blank' : '_self'"
          :to="productGroup.ext_url && productGroup.ext_url.length > 5 ? productGroup.ext_url :
            localePath({
              name: 'products-group',
              params: {
                group: $slugify(
                  productGroup.name
                    ? productGroup.name
                    : productGroup.fallback_name
                )
              }
            })
          "
        >
          <img
            :alt="
              productGroup.name
                ? productGroup.name
                : productGroup.fallback_name
            "
            :src="
              productGroup.images.length > 0
                ? productGroup.images[0]
                : '/images/placeholder-selector.jpg'
            "
            class="mx-auto"
            loading="lazy"
            height="81"
            width="121"/>
          <p class="text-center uppercase mt-2 mb-0">
            <strong>
              {{ productGroup.name ? productGroup.name : productGroup.fallback_name }}
            </strong>
          </p>
        </nuxt-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'BarCategories',
  props: {
    bg: {
      default: 'white',
      required: false,
      type: String
    },
    inline: {
      default: false,
      required: false,
      type: Boolean
    }
  },
  computed: {
    productGroups() {
      return this.$store.getters.getProductGroups
    }
  },
}
</script>
