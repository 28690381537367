<template>
  <section class="icon__slider py-16">
    <div class="container">
      <div class="flex flex-wrap">
        <div class="w-full px-4">
          <header-h4
            :content="$t('components.brands.header')"
            casing="uppercase"
            align="center"
            mt="0"
            mb="4"
          />
        </div>
        <div class="w-full px-4">
          <client-only>
            <agile
              ref="brandsSlider"
              :dots="false"
              :slides-to-show="6"
              :autoplay="true"
              :autoplay-speed="2000"
              :nav-buttons="false"
              :fade="false"
              :pauseOnHover="false"
            >
              <div v-for="brand in brands" class="slide">
                <a v-if="brand.acf && brand.acf.brand_url &&brand.acf.brand_url.length > 0" :href="brand.acf.brand_url" target="_blank">
                  <img
                       :src="brand.media.large"
                       :alt="brand.title"
                       height="100"
                       width="200"
                       loading="lazy" />
                </a>
                <img v-else
                  :src="brand.media.large"
                  :alt="brand.title"
                  height="100"
                  width="200"
                  loading="lazy" />
              </div>
            </agile>
          </client-only>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarIcons',
  computed: {
    brands() {
      return this.$store.getters.getBrands
    }
  }
}
</script>
