<template>
  <section class="bar__usps">
    <div class="container">
      <div class="flex flex-wrap">
        <div class="w-full tablet:w-1/2 px-4">
          <div class="flex flex-wrap -mx-4">
            <div
              v-for="column in barUsps.acf.columns"
              :key="column.image.ID"
              :class="
                `w-full tablet:w-1/${barUsps.acf.columns.length} px-4 text-center`
              "
            >
              <p class="text-center">
                <img
                  :alt="column.header"
                  :src="column.image.url"
                  class="mx-auto"
                  loading="lazy"
                  height="81"
                  width="121"
                />
              </p>
              <header-h6
                :content="column.header"
                color="gray-dark"
                align="center"
                casing="uppercase"
              />
              <div v-html="column.content" class="text-sm" />
            </div>
          </div>
        </div>
        <div class="w-full tablet:w-1/2 px-4 tablet:items-center tablet:flex">
          <div class="mx-auto text-sm flex flex-col">
            <div class="logo-wrapper mx-auto">
              <img
                :alt="$t('menu.used_car_parts')"
                src="~assets/images/logos/logo.svg"
                class="w-64 mx-auto"
                loading="lazy"
                height="133"
                width="300"
              />
            </div>
            <p v-html="$t('footer.address')"></p>
            <p>
              {{ $t('menu.e') }}
              <a :href="`mailto:${$t('menu.email')}`">{{
                $t('menu.email_view')
              }}</a
              ><br>
              <span v-if="language !== 'ie'">{{ $t('menu.f') }} {{ $t('menu.fax') }}</span>
               <br  v-if="language !== 'ie'">
              {{ $t('menu.p') }}
              <a
                :href="
                  `tel:${$t('menu.phone_number')
                    .replace('+', '00')
                    .replace(/ /g, '')}`
                "
                >{{ $t('menu.phone_number_view') }}</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarUsps',
  computed: {
    barUsps() {
      return this.$store.getters.getBarUsps
    },
    language() {
      return this.$store.getters.getLanguage
    }
  }
}
</script>

<style lang="scss">
.bar__usps .logo-wrapper {
  position: relative;

  &:before {
    content: "®";
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
