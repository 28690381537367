<template>
  <section class="bar__categories bg-gray-light">
    <div class="container">
      <client-only>
        <agile
          ref="brandsSlider"
          :dots="false"
          :slides-to-show="1"
          :autoplay="true"
          :autoplay-speed="5000"
          :nav-buttons="false"
          :fade="false"
          :pauseOnHover="false"
        >
          <div :key="newsItem.slug" v-for="newsItem in news">
            <div class="flex flex-wrap">
              <div
                class="w-full tablet:w-1/2 px-4 tablet:items-center tablet:flex"
              >
                <div>
                  <header-h4
                    :content="newsItem.title"
                    casing="uppercase"
                    mt="0"
                    mb="4"
                  />
                  <p v-html="newsItem.excerpt" />
                  <p class="mt-8 text-center">
                    <button-primary
                      :text="$t('components.news.read_more')"
                      :path="{
                        name: 'news',
                        params: {
                          slug: newsItem.slug
                        }
                      }"
                    />
                  </p>
                </div>
              </div>
              <div class="w-full tablet:w-1/2 px-4">
                <img
                  :src="newsItem.media.large"
                  :alt="newsItem.title"
                  style="height: 450px; width: 100%; object-fit: cover; object-position: top;"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </agile>
      </client-only>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarNews',
  computed: {
    news() {
      return this.$store.getters.getNews
    }
  }
}
</script>
