var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.inline)?_c('section',{class:("bar__categories bg-" + _vm.bg)},[_c('div',{staticClass:"container px-4 px-md-0"},[_c('div',{staticClass:"grid grid-cols-2 tablet:grid-cols-5 gap-5 laptop:gap-8"},_vm._l((_vm.productGroups),function(productGroup,groupIndex){return _c('nuxt-link',{key:'product-category-' + groupIndex,staticClass:"category",attrs:{"target":productGroup.ext_url && productGroup.ext_url.length > 5 ? '_blank' : '_self',"to":productGroup.ext_url && productGroup.ext_url.length > 5 ? productGroup.ext_url :
            _vm.localePath({
              name: 'products-group',
              params: {
                group: _vm.$slugify(
                  productGroup.name
                    ? productGroup.name
                    : productGroup.fallback_name
                )
              }
            })}},[_c('img',{staticClass:"category_img mx-auto",attrs:{"alt":productGroup.name
                ? productGroup.name
                : productGroup.fallback_name,"src":productGroup.images.length > 0
                ? productGroup.images[0]
                : '/images/placeholder-selector.jpg',"loading":"lazy","height":"81","width":"121"}}),_vm._v(" "),_c('div',{staticClass:"category_name"},[_c('strong',[_vm._v("\n              "+_vm._s(productGroup.name ? productGroup.name : productGroup.fallback_name)+"\n            ")])])])}),1)])]):_c('section',[_c('div',{staticClass:"grid grid-cols-1 tablet:grid-cols-3 gap-5 laptop:gap-8 px-4 pb-8"},_vm._l((_vm.productGroups),function(productGroup,groupIndex){return _c('nuxt-link',{key:'product-category-' + groupIndex,staticClass:"category",attrs:{"target":productGroup.ext_url && productGroup.ext_url.length > 5 ? '_blank' : '_self',"to":productGroup.ext_url && productGroup.ext_url.length > 5 ? productGroup.ext_url :
          _vm.localePath({
            name: 'products-group',
            params: {
              group: _vm.$slugify(
                productGroup.name
                  ? productGroup.name
                  : productGroup.fallback_name
              )
            }
          })}},[_c('img',{staticClass:"mx-auto",attrs:{"alt":productGroup.name
              ? productGroup.name
              : productGroup.fallback_name,"src":productGroup.images.length > 0
              ? productGroup.images[0]
              : '/images/placeholder-selector.jpg',"loading":"lazy","height":"81","width":"121"}}),_vm._v(" "),_c('p',{staticClass:"text-center uppercase mt-2 mb-0"},[_c('strong',[_vm._v("\n            "+_vm._s(productGroup.name ? productGroup.name : productGroup.fallback_name)+"\n          ")])])])}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }