<template>
  <footer id="layout_footer">
    <section id="main_footer">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-1/4 px-4">
            <header-h6
              :content="$t('footer.product_groups')"
              casing="uppercase"
              mt="0"
              mb="4"
              color="white"
            />
            <ul>
              <li v-for="productGroup in productGroups">
                <nuxt-link
                  :target="productGroup.ext_url && productGroup.ext_url.length > 5 ? '_blank' : '_self'"
                  :to="productGroup.ext_url && productGroup.ext_url.length > 5 ? productGroup.ext_url :
                    localePath({
                      name: 'products-group',
                      params: {
                        group: $slugify(
                          productGroup.name
                            ? productGroup.name
                            : productGroup.fallback_name
                        )
                      }
                    })
                  "
                  v-html="
                    productGroup.name
                      ? productGroup.name
                      : productGroup.fallback_name
                  "
                />
              </li>
            </ul>
          </div>
          <div class="w-full tablet:w-1/2 px-4">
            <header-h6
              :content="$t('footer.products')"
              casing="uppercase"
              mt="0"
              mb="4"
              color="white"
            />
            <div class="flex flex-wrap -mx-4">
              <div class="w-full tablet:w-1/2 px-4">
                <ul class="mt-0">
                  <li
                    v-for="brand in universal.slice(
                      0,
                      Math.ceil(universal.length / 2)
                    )"
                  >
                    <nuxt-link
                      :to="
                        localePath({
                          name: 'products-group-brand',
                          params: {
                            group: $slugify(
                              universalMain.name
                                ? universalMain.name
                                : universalMain.fallback_name
                            ),
                            brand: $slugify(
                              brand.name ? brand.name : brand.fallback_name
                            )
                          }
                        })
                      "
                      v-html="brand.name ? brand.name : brand.fallback_name"
                    />
                  </li>
                </ul>
              </div>
              <div class="w-full tablet:w-1/2 px-4">
                <ul class="mt-0">
                  <li
                    v-for="brand in universal.slice(
                      Math.ceil(universal.length / 2),
                      universal.length
                    )"
                  >
                    <nuxt-link
                      :to="
                        localePath({
                          name: 'products-group-brand',
                          params: {
                            group: $slugify(
                              universalMain.name
                                ? universalMain.name
                                : universalMain.fallback_name
                            ),
                            brand: $slugify(
                              brand.name ? brand.name : brand.fallback_name
                            )
                          }
                        })
                      "
                      v-html="brand.name ? brand.name : brand.fallback_name"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="w-full tablet:w-1/4 px-4">
            <header-h6
              :content="$t('footer.used_car_parts')"
              casing="uppercase"
              mt="0"
              mb="4"
              color="white"
            />
            <p v-html="$t('footer.address')"></p>
            <p>
              <br>{{ $t('menu.e') }}
              <a :href="$t('footer.email')">{{ $t('footer.email_view') }}</a>
              <br>
              <span v-if="language !== 'ie'">{{ $t('menu.f') }} {{ $t('menu.fax') }}</span>
               <br  v-if="language !== 'ie'">
              {{ $t('menu.p') }}
              <a :href="$t('footer.phone_number')">{{
                $t('footer.phone_number_view')
              }}</a>
            </p>
            <p>
              <a
                v-if="$i18n.locale === 'en'"
                href="https://twitter.com/APAIRLtd"
                target="_blank">
                <i class="fab fa-twitter text-3xl"/>
                <font-awesome-icon :icon="['fab', 'twitter']" class="text-3xl"/>
              </a>

              <a
                :href="$t('footer.facebook')"
                target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook-f']" class="text-3xl ml-4"/>
              </a>

              <a
                v-if="$i18n.locale === 'fr'"
                href="https://uk.linkedin.com/company/ap-air-sas"
                target="_blank">
                <font-awesome-icon :icon="['fab', 'linkedin-in']" class="text-3xl ml-4"/>
              </a>
              <a
                v-else
                href="https://uk.linkedin.com/company/apairltd"
                target="_blank">
                <font-awesome-icon :icon="['fab', 'linkedin-in']" class="text-3xl ml-4"/>
              </a>

              <a
                href=" https://www.instagram.com/apairltd/"
                target="_blank">
                <font-awesome-icon :icon="['fab', 'instagram']"  class="text-3xl ml-4"/>
              </a>

            </p>
          </div>
        </div>
      </div>
    </section>
    <!--    <section id="review_footer">-->
    <!--      <div class="container">-->
    <!--        <div class="flex flex-wrap">-->
    <!--          <div class="w-full px-4 text-center">-->
    <!--            <header-h6-->
    <!--              :content="$t('footer.review')"-->
    <!--              align="center"-->
    <!--              casing="uppercase"-->
    <!--              mt="0"-->
    <!--              mb="0"-->
    <!--            />-->
    <!--            <p class="text-2xl stars">-->
    <!--              <i v-if="rating >= 1" class="fas fa-star" />-->
    <!--              <i v-else class="fas fa-star-half-alt" />-->

    <!--              <i v-if="rating >= 2" class="fas fa-star" />-->
    <!--              <i v-else-if="rating >= 1" class="fas fa-star-half-alt" />-->
    <!--              <i v-else class="far fa-star" />-->

    <!--              <i v-if="rating >= 3" class="fas fa-star" />-->
    <!--              <i v-else-if="rating >= 2" class="fas fa-star-half-alt" />-->
    <!--              <i v-else class="far fa-star" />-->

    <!--              <i v-if="rating >= 4" class="fas fa-star" />-->
    <!--              <i v-else-if="rating >= 3" class="fas fa-star-half-alt" />-->
    <!--              <i v-else class="far fa-star" />-->

    <!--              <i v-if="rating >= 5" class="fas fa-star" />-->
    <!--              <i v-else-if="rating >= 4" class="fas fa-star-half-alt" />-->
    <!--              <i v-else class="far fa-star" />-->
    <!--            </p>-->
    <!--            <p class="hidden">-->
    <!--              <i class="fas fa-star" />-->
    <!--              <i class="fas fa-star-half-alt" />-->
    <!--              <i class="far fa-star" />-->
    <!--            </p>-->
    <!--                        <p>-->
    <!--                          {{-->
    <!--                            $t('components.bars.google_reviews.header')-->
    <!--                              .replace('%1', numberOfReviews)-->
    <!--                              .replace('%2', rating)-->
    <!--                          }}-->
    <!--                          <a-->
    <!--                            href="https://www.google.com/maps/place/AP+Air+Ltd/@51.7232159,-2.3728942,17z/data=!3m1!4b1!4m7!3m6!1s0x0:0x7f4ab83fe9a3bae4!8m2!3d51.7232159!4d-2.3707055!9m1!1b1"-->
    <!--                            target="_blank"-->
    <!--                            >{{ $t('components.bars.google_reviews.view') }}</a-->
    <!--                          >.-->
    <!--                        </p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
    <section id="conditions_footer">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full px-4 text-center">
            <p>
              <span class="mx-8 copy-info">Copyright by AP AIR EUROPE LTD</span>
              <nuxt-link
                :to="localePath('conditions')"
                v-html="$t('footer.terms')"
              />
              <span v-html="`|`" class="mx-2" />
              <nuxt-link
                :to="localePath('privacy')"
                v-html="$t('footer.privacy')"
              />
              <span v-html="`|`" class="mx-2" />
              <nuxt-link
                :to="localePath('cookie')"
                v-html="$t('footer.cookie')"
              />
              <span v-html="`|`" class="mx-2" />
              <a
                href="https://apair-cms.apairltd.com/wp-content/uploads/2023/10/apair@clarityscanning.co_.uk_20231025_114553.pdf"
                target="_blank"
                rel="nofollow noopener noreferrer">
                {{ $t('footer.isoCertification') }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <client-only>
      <cookie-law
        :buttonText="$t('cookies.buttonText')"
        :buttonLink="localePath('cookie')"
        :buttonLinkText="$t('cookies.linkText')"
        :message="$t('cookies.message')"
        theme="mytheme"
      />
    </client-only>
  </footer>
</template>

<script>
// import portal from '../../service/portal' // TODO Disabled because of removing Google Reviews

export default {
  name: 'Footer',
  data() {
    return {
      numberOfReviews: 0,
      rating: 0.0
    }
  },
  computed: {
    productGroups() {
      return this.$store.getters.getProductGroups
    },
    universalMain() {
      return this.$store.getters.getUniversalMain
    },
    universal() {
      return this.$store.getters.getUniversal
    },
    language() {
      return this.$store.getters.getLanguage
    }
  },
  created() {
    // TODO Disabled because of removing Google Reviews
    /* Promise.all([portal.get('google-reviews')]).then(([reviews]) => {
      this.numberOfReviews = reviews.data.reviews
      this.rating = parseFloat(reviews.data.rating)
    }) */
  }
}
</script>

<style lang="scss">
.Cookie--mytheme {
  @apply text-white bg-gray-dark px-8 py-4 text-base;
}

.Cookie--mytheme .Cookie__button:first-child {
  @apply inline text-white underline;
  &:hover {
    @apply text-red;
  }
}

.Cookie--mytheme .Cookie__button:last-child {
  @apply inline-block text-center px-4 py-2 text-base leading-normal bg-transparent border-2 border-solid border-red text-red  uppercase text-2xl relative z-10;

  &:before {
    @apply absolute top-0 right-0 bottom-0 left-0 bg-red origin-top-left transition-all duration-200;
    z-index: -1;
    transform: scaleX(0);
    content: '';
  }

  &:hover {
    @apply text-white;

    &:before {
      transform: scaleX(1);
    }
  }
}
@media (max-width: 768px){
  .copy-info {
    width: 100%;
    display: inline-block;
    margin: 5px 0 15px!important;
  }
}
</style>
