<template>
  <div>
    <div class="selector hidden tablet:block mb-4">
      <div class="product-groups">
        <div class="flex flex-wrap -mx-1 py-4">
          <div class="w-full px-1">
            <header-h6
              :content="$t('components.products.selector.step_1')"
              casing="uppercase"
              mt="0"
              mb="2"
            />
          </div>
          <div
            v-for="productGroup in productGroups"
            class="w-full tablet:w-1/3 px-1"
          >
            <div
              :class="
                `mb-2 p-2 border border-${
                  (activeGroup.name === productGroup.name &&
                    activeGroup.name !== '') ||
                  activeGroup.fallback_name === productGroup.fallback_name
                    ? 'red'
                    : 'gray-medium'
                } border-solid`
              "
            >
              <nuxt-link
                :target="productGroup.ext_url && productGroup.ext_url.length > 5 ? '_blank' : '_self'"
                :to="productGroup.ext_url && productGroup.ext_url.length > 5 ? productGroup.ext_url :
                  localePath({
                    name: 'products-group',
                    params: {
                      group: $slugify(
                        productGroup.name
                          ? productGroup.name
                          : productGroup.fallback_name
                      )
                    }
                  })
                "
              >
                <img
                  :alt="
                    productGroup.name
                      ? productGroup.name
                      : productGroup.fallback_name
                  "
                  :title="
                    productGroup.name
                      ? productGroup.name
                      : productGroup.fallback_name
                  "
                  :src="
                    productGroup.images.length > 0
                      ? productGroup.images[0]
                      : '/images/placeholder-selector.jpg'
                  "
                  class="mx-auto"
                />
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="brands.length > 0" class="brands">
        <div class="flex flex-wrap -mx-1 py-4">
          <div class="w-full px-1">
            <header-h6
              :content="$t('components.products.selector.step_2')"
              casing="uppercase"
              mt="0"
              mb="2"
            />
            <div class="form_item">
              <select
                v-model="brandId"
                @change="changeBrand"
                class="form_input"
              >
                <option
                  v-html="$t('components.products.selector.step_2').substr(3)"
                  value=""
                />
                <option
                  v-for="brand in brands"
                  :value="brand.id"
                  v-html="brand.name"
                />
              </select>
            </div>
          </div>
        </div>
      </div>
      <div v-if="types.length > 0" class="types">
        <div class="flex flex-wrap -mx-1 py-4">
          <div class="w-full px-1">
            <header-h6
              :content="$t('components.products.selector.step_3')"
              casing="uppercase"
              mt="0"
              mb="2"
            />
            <div class="form_item">
              <select v-model="typeId" @change="changeType" class="form_input">
                <option
                  v-html="$t('components.products.selector.step_3').substr(3)"
                  value=""
                />
                <option
                  v-for="type in types"
                  :value="type.id"
                  v-html="type.name"
                />
              </select>
            </div>
          </div>
        </div>
      </div>
      <div v-if="models.length > 0" class="models">
        <div class="flex flex-wrap -mx-1 py-4">
          <div class="w-full px-1">
            <header-h6
              :content="$t('components.products.selector.step_4')"
              casing="uppercase"
              mt="0"
              mb="2"
            />
            <div class="form_item">
              <select
                v-model="modelId"
                @change="changeModel"
                class="form_input"
              >
                <option
                  v-html="$t('components.products.selector.step_4').substr(3)"
                  value=""
                />
                <option
                  v-for="model in models"
                  :value="model.id"
                  v-html="model.name ? model.name : model.fallback_name"
                />
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-b border-t pt-4 border-gray-medium border-solid">
      <header-h4
        :content="$t('components.products.help.header')"
        casing="uppercase"
        mt="0"
        mb="4"
      />
      <div class="flex flex-wrap -mx-4 my-4">
        <div class="w-1/5 px-4 hidden tablet:flex tablet:items-center">
          <font-awesome-icon :icon="['fas', 'phone']" flip="horizontal" class="text-red text-4xl"/>
        </div>
        <div class="w-full tablet:w-4/5 px-4">
          <strong
            v-html="$t('components.products.help.call')"
            class="uppercase"
          /><br>
          <span v-html="$t('components.products.help.call_action')" />
        </div>
      </div>
      <!--      <div v-if="$i18n.locale !== 'fr'" class="flex flex-wrap -mx-4 my-4">-->
      <!--        <div class="w-1/5 px-4 hidden tablet:flex tablet:items-center">-->
      <!--          <i class="fab fa-whatsapp text-red text-4xl" />-->
      <!--        </div>-->
      <!--        <div class="w-full tablet:w-4/5 px-4">-->
      <!--          <strong-->
      <!--            v-html="$t('components.products.help.whatsapp')"-->
      <!--            class="uppercase"-->
      <!--          /><br>-->
      <!--          <span v-html="$t('components.products.help.whatsapp_action')" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="flex flex-wrap -mx-4 my-4">
        <div class="w-1/5 px-4 hidden tablet:flex tablet:items-center">
          <font-awesome-icon :icon="['fas', 'envelope']" class="text-red text-4xl"/>
        </div>
        <div class="w-full tablet:w-4/5 px-4">
          <strong
            v-html="$t('components.products.help.email')"
            class="uppercase"
          /><br>
          <span v-html="$t('components.products.help.email_action')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import portal from '../../service/portal'

export default {
  name: 'Sidebar',
  props: {
    data: {
      default: () => [],
      required: false,
      type: Array
    }
  },
  data() {
    return {
      productGroups: this.$store.getters.getProductGroups,
      brands: this.data.length > 0 ? this.data[0].brands : [],
      types: this.data.length > 1 ? this.data[1].types : [],
      models: this.data.length > 2 ? this.data[2].models : [],
      brandId: this.data.length > 1 ? this.data[1].activeBrand.id : '',
      typeId: this.data.length > 2 ? this.data[2].activeType.id : '',
      modelId: this.data.length > 3 ? this.data[3].activeModel.id : '',
      activeGroup: this.data.length > 0 ? this.data[0].activeGroup : {},
      activeBrand: this.data.length > 1 ? this.data[1].activeBrand : {},
      activeType: this.data.length > 2 ? this.data[2].activeType : {},
      activeModel: this.data.length > 3 ? this.data[3].activeModel : {}
    }
  },
  async created() {
    // if (this.data.length === 1) {
    //   const [brands] = await Promise.all([
    //     portal.get(
    //       `productgroups/${this.activeGroup.id}?lang=${this.$store.state.language}`
    //     )
    //   ])
    //   const newBrands = []
    //   for (let i = 0; i < brands.data[0].brands.length; i++) {
    //     const brand = brands.data[0].brands[i]
    //     if (brands.data[0].brands[i] !== null) {
    //       for (
    //         let j = 0;
    //         j < brands.data[0].brands[i].translations.length;
    //         j++
    //       ) {
    //         if (
    //           brands.data[0].brands[i].translations[j].language
    //             .language_code === 'en'
    //         ) {
    //           brand.fallback_name =
    //             brands.data[0].brands[i].translations[j].name
    //         }
    //         if (
    //           brands.data[0].brands[i].translations[j].language
    //             .language_code === this.$store.state.language
    //         ) {
    //           brand.name = brands.data[0].brands[i].translations[j].name
    //         }
    //       }
    //       newBrands.push(brand)
    //     }
    //   }
    //   this.brands = newBrands
    // }
    //
    // if (this.data.length === 2) {
    //   const [brands, vehicleTypes] = await Promise.all([
    //     portal.get(
    //       `productgroups/${this.activeGroup.id}?lang=${this.$store.state.language}`
    //     ),
    //     portal.get(
    //       `productgroups/${this.activeGroup.id}/brands/${this.activeBrand.id}?lang=${this.$store.state.language}`
    //     )
    //   ])
    //   const newBrands = []
    //   for (let i = 0; i < brands.data[0].brands.length; i++) {
    //     const brand = brands.data[0].brands[i]
    //     if (brands.data[0].brands[i] !== null) {
    //       for (
    //         let j = 0;
    //         j < brands.data[0].brands[i].translations.length;
    //         j++
    //       ) {
    //         if (
    //           brands.data[0].brands[i].translations[j].language
    //             .language_code === 'en'
    //         ) {
    //           brand.fallback_name =
    //             brands.data[0].brands[i].translations[j].name
    //         }
    //         if (
    //           brands.data[0].brands[i].translations[j].language
    //             .language_code === this.$store.state.language
    //         ) {
    //           brand.name = brands.data[0].brands[i].translations[j].name
    //         }
    //       }
    //       newBrands.push(brand)
    //     }
    //   }
    //   this.brands = newBrands
    //
    //   const newVehicleTypes = []
    //   for (let i = 0; i < vehicleTypes.data[0].vehicle_types.length; i++) {
    //     const vehicleType = vehicleTypes.data[0].vehicle_types[i]
    //     if (vehicleTypes.data[0].vehicle_types[i] !== null) {
    //       for (
    //         let j = 0;
    //         j < vehicleTypes.data[0].vehicle_types[i].translations.length;
    //         j++
    //       ) {
    //         if (
    //           // eslint-disable-next-line standard/computed-property-even-spacing
    //           vehicleTypes.data[0].vehicle_types[i].translations[j].language
    //             .language_code === 'en'
    //         ) {
    //           vehicleType.fallback_name =
    //             // eslint-disable-next-line standard/computed-property-even-spacing
    //             vehicleTypes.data[0].vehicle_types[i].translations[j].name
    //         }
    //         if (
    //           // eslint-disable-next-line standard/computed-property-even-spacing
    //           vehicleTypes.data[0].vehicle_types[i].translations[j].language
    //             .language_code === this.$store.state.language
    //         ) {
    //           vehicleType.name =
    //             // eslint-disable-next-line standard/computed-property-even-spacing
    //             vehicleTypes.data[0].vehicle_types[i].translations[j].name
    //         }
    //       }
    //       newVehicleTypes.push(vehicleType)
    //     }
    //   }
    //   this.types = newVehicleTypes
    // }
    //
    // if (this.data.length === 3) {
    //   const [brands, vehicleTypes, models] = await Promise.all([
    //     portal.get(
    //       `productgroups/${this.activeGroup.id}?lang=${this.$store.state.language}`
    //     ),
    //     portal.get(
    //       `productgroups/${this.activeGroup.id}/brands/${this.activeBrand.id}?lang=${this.$store.state.language}`
    //     ),
    //     portal.get(
    //       `vehicle-types/${this.activeType.id}?lang=${this.$store.state.language}`
    //     )
    //   ])
    //   const newBrands = []
    //   for (let i = 0; i < brands.data[0].brands.length; i++) {
    //     const brand = brands.data[0].brands[i]
    //     if (brands.data[0].brands[i] !== null) {
    //       for (
    //         let j = 0;
    //         j < brands.data[0].brands[i].translations.length;
    //         j++
    //       ) {
    //         if (
    //           brands.data[0].brands[i].translations[j].language
    //             .language_code === 'en'
    //         ) {
    //           brand.fallback_name =
    //             brands.data[0].brands[i].translations[j].name
    //         }
    //         if (
    //           brands.data[0].brands[i].translations[j].language
    //             .language_code === this.$store.state.language
    //         ) {
    //           brand.name = brands.data[0].brands[i].translations[j].name
    //         }
    //       }
    //       newBrands.push(brand)
    //     }
    //   }
    //   this.brands = newBrands
    //
    //   const newVehicleTypes = []
    //   for (let i = 0; i < vehicleTypes.data[0].vehicle_types.length; i++) {
    //     const vehicleType = vehicleTypes.data[0].vehicle_types[i]
    //     if (vehicleTypes.data[0].vehicle_types[i] !== null) {
    //       for (
    //         let j = 0;
    //         j < vehicleTypes.data[0].vehicle_types[i].translations.length;
    //         j++
    //       ) {
    //         if (
    //           // eslint-disable-next-line standard/computed-property-even-spacing
    //           vehicleTypes.data[0].vehicle_types[i].translations[j].language
    //             .language_code === 'en'
    //         ) {
    //           vehicleType.fallback_name =
    //             // eslint-disable-next-line standard/computed-property-even-spacing
    //             vehicleTypes.data[0].vehicle_types[i].translations[j].name
    //         }
    //         if (
    //           // eslint-disable-next-line standard/computed-property-even-spacing
    //           vehicleTypes.data[0].vehicle_types[i].translations[j].language
    //             .language_code === this.$store.state.language
    //         ) {
    //           vehicleType.name =
    //             // eslint-disable-next-line standard/computed-property-even-spacing
    //             vehicleTypes.data[0].vehicle_types[i].translations[j].name
    //         }
    //       }
    //       newVehicleTypes.push(vehicleType)
    //     }
    //   }
    //   this.types = newVehicleTypes
    //
    //   const newModels = []
    //   for (let i = 0; i < models.data[0].vehicles.length; i++) {
    //     const model = models.data[0].vehicles[i]
    //
    //     for (
    //       let j = 0;
    //       j < models.data[0].vehicles[i].translations.length;
    //       j++
    //     ) {
    //       if (
    //         models.data[0].vehicles[i].translations[j].language
    //           .language_code === 'en'
    //       ) {
    //         model.fallback_name =
    //           models.data[0].vehicles[i].translations[j].name
    //       }
    //       if (
    //         models.data[0].vehicles[i].translations[j].language
    //           .language_code === this.$store.state.language
    //       ) {
    //         model.name = models.data[0].vehicles[i].translations[j].name
    //       }
    //     }
    //     newModels.push(model)
    //   }
    //   this.models = newModels
    // }
  },
  methods: {
    changeBrand() {
      let selectedBrand = {}
      for (let i = 0; i < this.brands.length; i++) {
        if (this.brands[i].id === this.brandId) {
          selectedBrand = this.brands[i]
        }
      }
      window.location.href = this.localePath({
        name: 'products-group-brand',
        params: {
          group: this.$slugify(
            this.activeGroup.name
              ? this.activeGroup.name
              : this.activeGroup.fallback_name
          ),
          brand: this.$slugify(
            selectedBrand.name
              ? selectedBrand.name
              : selectedBrand.fallback_name
          )
        }
      })
    },
    changeType() {
      let selectedType = {}
      for (let i = 0; i < this.types.length; i++) {
        if (this.types[i].id === this.typeId) {
          selectedType = this.types[i]
        }
      }
      window.location.href = this.localePath({
        name: 'products-group-brand-type',
        params: {
          group: this.$slugify(
            this.activeGroup.name
              ? this.activeGroup.name
              : this.activeGroup.fallback_name
          ),
          brand: this.$slugify(
            this.activeBrand.name
              ? this.activeBrand.name
              : this.activeBrand.fallback_name
          ),
          type: this.$slugify(
            selectedType.name ? selectedType.name : selectedType.fallback_name
          )
        }
      })
    },
    changeModel() {
      let selectedModel = {}
      for (let i = 0; i < this.models.length; i++) {
        if (this.models[i].id === this.modelId) {
          selectedModel = this.models[i]
        }
      }
      window.location.href = this.localePath({
        name: 'products-group-brand-type-model',
        params: {
          group: this.$slugify(
            this.activeGroup.name
              ? this.activeGroup.name
              : this.activeGroup.fallback_name
          ),
          brand: this.$slugify(
            this.activeBrand.name
              ? this.activeBrand.name
              : this.activeBrand.fallback_name
          ),
          type: this.$slugify(
            this.activeType.name
              ? this.activeType.name
              : this.activeType.fallback_name
          ),
          model: this.$slugify(
            selectedModel.name
              ? selectedModel.name
              : selectedModel.fallback_name
          )
        }
      })
    }
  }
}
</script>
